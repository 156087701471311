.centering {
  max-width: 700px;
  margin: 0 auto;
}

.vollewidth {
  background-color: #2d4263;
  max-width: 2000px;
}

.paypalwidth {
  max-width: 200px;
}

.img-width {
  max-width: 300px;
  border-radius: 7px;
}

.img-breit {
  max-width: 400px;
  border-radius: 7px;
}

.margin-rund {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.abstand1 {
  height: 50px;
}

.richtig {
  background-color: green;
}

.nah {
  background-color: yellow;
}

.bkrreg {
  background-color: #ecdbba;
}

.bkrbl {
  background-color: #2d4263;
}

.h50p {
  height: 85vh;
  border-radius: 11px;
}

.namensschrift {
  font-size: 2.8em;
  color: #2d4263;
  font-family: "Lucida Console", Times, sans-serif;
  font-style: italic;
  font-weight: 900;
}

.loesungsnamen {
  font-size: 0.8em;
  color: #2d4263;
  font-family: "Lucida Console", Times, sans-serif;
  font-style: italic;
  font-weight: 600;
}

.datumschrift {
  font-size: 1.1em;
  color: #c84b31;
  font-family: "Lucida Console", Times, sans-serif;
  /* font-style: italic; */
  font-weight: 500;
}

.frageschrift {
  font-size: 0.9em;
  color: #ecdbba;
  font-family: Georgia, Times, sans-serif;
  /* font-style: italic; */
  font-weight: 300;
}

.aufloesung {
  font-size: 0.9em;
  color: #ecdbba;
  font-family: Georgia, Times, sans-serif;
  /* font-style: italic; */
  font-weight: 300;
}

button {
  margin-top: 10px;
  padding: 8px 12px;
  background: #2d4263;
  border: 0;
  font-weight: bold;
  font-family: "Lucida Console", Times, sans-serif;
  cursor: pointer;
  border-radius: 7px !important;
  color: #c84b31;
}

.augewbtn {
  margin-top: 10px;
  padding: 8px 12px;
  background: #ecdbba;
  border: 0;
  font-weight: bold;
  font-family: "Lucida Console", Times, sans-serif;
  cursor: pointer;
  border-radius: 7px !important;
  color: #c84b31;
}

.textcenter {
  text-align: center;
}

.punktestand {
  font-size: 4.5em;
  color: #c84b31;
  font-family: "Lucida Console", Times, sans-serif;
  /* font-style: italic; */
  font-weight: 900;
}

.inputround {
  border-radius: 8px;
  background: #2d4263;
  border: 2px solid #c84b31;
  padding: 6px;

  color: #ecdbba;
}

.imschrift {
  font-size: 0.6em;
  color: #ecdbba;
  font-family: Georgia, Times, sans-serif;
  /* font-style: italic; */
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
}
